.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.border-0 {
  border: 0px !important;
}

//===================================================================
.identiv-bg-default {
  background-color: #D0112B;
}

.identiv-text-default {
  color: white;
}

.identiv-btn-basic {
  color: white;
  background-color: #595959;
}
.identiv-btn-basic:hover {
  color: white;
  background-color: #494343;
}
.identiv-dropdown-megamenu {
  padding: 20px;
  left: 40px !important;
  right: 40px !important;
}
.fwicon {
  display: block;
  font-size: 40px;
  color: blue;
}
.fc-daygrid-event-dot-green { /* the actual dot */
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 4px solid green;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color,green);
  border-radius: 4px;
  border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
  display: inline-block; 
}
.fc-daygrid-event-dot-gray { /* the actual dot */
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 4px solid gray;
  border: calc(var(--fc-daygrid-event-dot-width, 8px) / 2) solid var(--fc-event-border-color,gray);
  border-radius: 4px;
  border-radius: calc(var(--fc-daygrid-event-dot-width, 8px) / 2);
  display: inline-block; 
}

.swal2-progressProvisioning {
  font-size: 0.4rem !important;
  opacity: 0.8;
  width: 300px !important;
}

.swal2-progressVerifying {
  font-size: 0.8rem !important;
  // opacity: 0.8;
  width: 600px !important;
}

.spinner-grow-8 {
  width: 8px;
  height: 8px;
}