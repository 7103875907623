@import "./icons.scss";

// Light Theme
// @import "./bootstrap.scss";
// @import "./app.scss";

// Dark Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
@import "./bootstrap.scss";
@import "./app.scss";

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

// .custom-control-input {
//   position: absolute !important;
//   left: 0 !important;
//   z-index: -1 !important;
//   width: 1rem !important;
//   height: 1.10938rem !important;
//   opacity: 0 !important;
// }

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

//chat style dot
// .conversation-list .dropdown .dropdown-toggle{
//   font-size: 18px !important;
//   padding: 4px !important;
//   color: #74788d !important;
// }

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background : #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
    
  }
}

//Two verification Page
.verification{
 input{
  &:focus{
    border: none;
    outline: none !important;
  }
 }
}

@media (max-width: 768px) {
  .verification{
    input{
      width: 40px !important;
   
    }
   }
}

@media (max-width: 320px) {
  .verification{
    input{
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
   
    }
   }
}

//Two verification Page - 2
.verification-2{
  input{
   &:focus{
     border: none;
     outline: none !important;
   }
  }
 }
 
 @media (max-width: 768px) {
   .verification-2{
     input{
       width: 40px !important;
       font-size: 14px;
    
     }
    }
 }
 
 @media (max-width: 320px) {
   .verification-2{
     input{
       width: 30px !important;
       height: 35px !important;
       font-size: 15px !important;
    
     }
    }
 }